import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import GamesCard from "../../component/GamesCard";
import axios from "axios";

import landingVid from "../../assets/Landing.mp4";
import { ColorRing } from "react-loader-spinner";
import TrustPilot from "../../component/TrustPilot/TrustPilot";
import TrustPilotReviews from "../../component/TrustPilot/TrustPilotReviews";

export default function Home() {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/home`
        );
        setData(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  let bodyData = data?.body;
  return (
    <>
      {isLoading ? (
        <>
          <div className="d-flex justify-content-center align-items-center w-100 vh-100">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
            />
          </div>
        </>
      ) : (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Bils Services</title>
            <link rel="canonical" href="http://mysite.com/example" />
          </Helmet>
          <section className="">
            <div className="container py-5">
              <div className="row g-3">
                <div className="col-md-7 text-white align-self-center">
                  <div className="card blurBg blue-color3Opicity">
                    <div className="blue-color3Opicity rounded-2">
                      <div className="card-body">
                        <blockquote className="blockquote blockquote-custom  px-3 pt-4">
                          <div className="blockquote-custom-icon bg-info shadow-1-strong">
                            <i className="fa fa-quote-left text-white"></i>
                          </div>
                          <h2 className="h4 text-blue fw-bold">
                            {data?.title}
                          </h2>
                          <hr />
                          <div
                            className="fs-6 lineHeight"
                            dangerouslySetInnerHTML={{ __html: bodyData }}
                          ></div>
                          <hr />
                          <footer className="d-flex flex-column gap-2 justify-content-center align-items-center">
                            <div className=" text-blue fw-bold">
                              Our Socials
                            </div>
                            <ul className=" list-unstyled d-flex m-0 align-items-center justify-content-center gap-4">
                              <li
                                className="d-flex justify-content-center align-items-center blue-color rounded-2 "
                                style={{ width: "40px", height: "40px" }}
                              >
                                <a
                                  href="https://www.facebook.com/bilsservicess/"
                                  className="text-decoration-none text-white"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <i className="fa-brands fa-facebook-f pt-1"></i>
                                </a>
                              </li>
                              <li
                                className="d-flex justify-content-center align-items-center blue-color rounded-2 "
                                style={{ width: "40px", height: "40px" }}
                              >
                                <a
                                  href="https://discord.com/invite/bils07"
                                  className="text-decoration-none text-white"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <i className="fa-brands fa-discord p-2"></i>
                                </a>
                              </li>
                              <li
                                className="d-flex justify-content-center align-items-center bg-white border border-1 border-primary rounded-2 p-3 "
                                style={{ width: "140px", height: "40px" }}
                              >
                                <TrustPilot />
                              </li>
                            </ul>
                          </footer>
                        </blockquote>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 offset-md-1 text-center neon">
                  <video
                    src={landingVid}
                    className="w-100 rounded"
                    autoPlay
                    muted
                    playsInline
                    loop
                  />
                </div>
              </div>
            </div>
          </section>
          <GamesCard />
          {/* <TrustPilotReviews /> */}
        </>
      )}
    </>
  );
}
